/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import {
  faCheck, faMinus, faUpload, faTimes, faColumns, faRefresh, 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Swal from 'sweetalert2';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { getUserSavingInvestments, getSavingInvestments, deleteSavingInvestment } from '../redux/savingInvestment/savingInvestmentActions';
import { CopyToClipboardCmp, ModalFilters } from '../components';
import FullPageTransparentLoader from '../pages/FullPageTransparentLoader/fullPageTransparentLoader';
import { dateOptions, omitColumn } from './helper';
import { savingsInvestmentColumns } from '../columnsDefaultConfig/savingsInvestmetColumns';

export function SavingInvestmentsDatatable({ userId = null }) {
  const filtersStorageName = 'DataTable_Savings_History/filters'; 
  const paginationStorageName = 'DataTable_Savings_History/pagination';
  const columnsStorageName = 'DataTable_Savings_History/savings-history_columns';
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState([]);
  const [endDateFilter, setEndDateFilter] = useState([]);
  const [columnConfig, setColumnConfig] = useState(savingsInvestmentColumns);
  const { paginatedData, totalCount } = useSelector((state) => state.stakingInvestments);

  const setStoredPagination = () => {
    const savingInvestmentJSON = localStorage.getItem(paginationStorageName);
    if (savingInvestmentJSON) {
      const filterRows = JSON.parse(savingInvestmentJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }
    
    setIsPaginationDT(true);
  };

  const setStoredColumnsData = () => {
    const savingsHistoryColumnsStorage = localStorage.getItem(columnsStorageName);
    if (savingsHistoryColumnsStorage) {
      const columns = JSON.parse(savingsHistoryColumnsStorage);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredFilterData = () => {
    const savingsInvestmentFiltersJSON = localStorage.getItem(filtersStorageName);
    const filters = JSON.parse(savingsInvestmentFiltersJSON);
    setFilters(filters);
  };

  useEffect(() => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (isPaginationDT) {
        localStorage.setItem(filtersStorageName, JSON.stringify(filters));
        await dispatch(userId ? getUserSavingInvestments(userId, { page, rowsPerPage, filters }) : getSavingInvestments({ page, rowsPerPage, filters }));
      }
      setIsLoading(false);
    };

    fetchData();
  }, [userId, filters, isPaginationDT, page, rowsPerPage]);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete this record?',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then(({ isConfirmed }) => {
        if (isConfirmed) dispatch(deleteSavingInvestment(id));
      });
  };
  const refresh = () => {
    setStoredFilterData();
    setStoredPagination();
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ page, limit: currentRowsPerPage }));
    setRowsPerPage(currentRowsPerPage);
  };

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ page, limit: rowsPerPage }));
    setPage(page);
  };

  const handleClear = () => {
    setStartDateFilter([]);
    setEndDateFilter([]);
    setFilters({});
    localStorage.removeItem(filtersStorageName);
  };

  const toggleColumn = (name) => {    
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const columns = [
    {
      name: 'Id',
      cell: ({ _id }) => <CopyToClipboardCmp label={_id} isNeedToCut />,
      omit: omitColumn(columnConfig, 'ID'), 
      width: '150px',
    },
    {
      name: 'User',
      cell: ({ user }) => (user ? <Link className="text-white" to={`/user-detail/${user._id}`}>{`${user.firstName} ${user.lastName}`}</Link> : 'User Not Found'),
      omit: omitColumn(columnConfig, 'User'), 
      width: '250px',
    },
    {
      name: 'Invested Amount',
      cell: ({ investedAmount, currency }) => <span>{`${investedAmount.toFixed(2)} ${currency.symbol}`}</span>,
      omit: omitColumn(columnConfig, 'Invested Amount'), 
      width: '200px',
    },
    {
      name: 'Final Earning',
      cell: ({ finalEarnings, currency }) => (finalEarnings ? <span>{`${finalEarnings.toFixed(2)} ${currency.symbol}`}</span> : <FontAwesomeIcon icon={faMinus} />),
      omit: omitColumn(columnConfig, 'Final Earning'), 
      width: '200px',
    },
    {
      name: 'Period',
      cell: ({ period, isFlexible }) => `${!isFlexible ? `Locked ${period}` : period}`,
      omit: omitColumn(columnConfig, 'Period'), 
      width: '200px',
    },
    {
      name: 'Percent',
      omit: omitColumn(columnConfig, 'Percent'), 
      cell: ({ pct }) => `${pct} %`,
    },
    {
      name: (
        <ModalFilters
          onClick={(e) => e.stopPropagation()}
          data={dateOptions}
          filters={startDateFilter}
          setFilters={setStartDateFilter}
          tabName="Start Date" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
          isDateOptions
        />
      ),
      cell: ({ createdAt }) => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      omit: omitColumn(columnConfig, 'Start Date'), 
      width: '250px',
    },
    {
      name: (
        <ModalFilters
          onClick={(e) => e.stopPropagation()}
          data={dateOptions}
          filters={endDateFilter}
          setFilters={setEndDateFilter}
          tabName="Release Date" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
          isDateOptions
        />
      ),
      cell: ({ releaseTime }) => (releaseTime ? moment(releaseTime).format('YYYY-MM-DD HH:mm:ss') : <FontAwesomeIcon icon={faMinus} />), 
      omit: omitColumn(columnConfig, 'Release Date'), 
      width: '250px',
    },
    {
      name: 'Flexible',
      omit: omitColumn(columnConfig, 'Flexible'), 
      cell: ({ isFlexible }) => <FontAwesomeIcon icon={isFlexible ? faCheck : faMinus} />,
    },
    {
      name: 'Closed',
      omit: omitColumn(columnConfig, 'Closed'), 
      cell: ({ isReleased }) => <FontAwesomeIcon icon={isReleased ? faCheck : faMinus} />,
    },
    {
      name: 'Actions',
      cell: ({ _id }) => <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDelete(_id)}>Delete</button>,
      omit: omitColumn(columnConfig, 'Actions'), 
      width: '250px',
    },
  ];

  return (
    <>
      <div className="action__btn-row">
        <div className="main_btn-row">
          <div className="secondary_btn-row">
            <Dropdown className="leads-columns-dropdown">
              <Dropdown.Toggle 
                variant="" 
                className="btn-secondary_dark"
              >
                <FontAwesomeIcon
                  icon={faColumns}
                  size="xs"
                  style={{ padding: '5px' }}
                />
                Columns
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ul className="leads-columns-list">
                  {columnConfig.map((column) => (
                    <li className="leads-columns-option" key={column.name} onClick={() => toggleColumn(column.name)}>
                      {column.selected ? (
                        <FontAwesomeIcon
                          size="xs"
                          icon={faCheck}
                          color="#6E7F95"
                        />
                      ) : null}
                      <span className="leads-columns-option__name">{column.name}</span>
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <button 
              type="button"
              className="btn-secondary_dark iconed"
              onClick={handleClear}
            >
              <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
            </button>
            <button 
              type="button" 
              className="btn-secondary_dark iconed"
              onClick={refresh}
            >
              <RefreshIcon sx={{ fontSize: '20px' }} />
            </button>
          </div>
        </div>  
      </div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        {
          isPaginationDT
          && (
          <DataTable
            columns={columns} 
            data={paginatedData} 
            pagination 
            paginationServer
            paginationTotalRows={totalCount}
            paginationPerPage={rowsPerPage}
            paginationDefaultPage={page}
            paginationRowsPerPageOptions={[10, 20, 50, 100]} 
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            theme="solarizedd"
            progressPending={isLoading}
            progressComponent={<div className="datatable-loader__background" />}
          />
          )
        }
      </div>
    </>
  );
};
