import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DatePicker from 'react-multi-date-picker';
import opacity from 'react-element-popper/animations/opacity';
import transition from 'react-element-popper/animations/transition';
import 'react-multi-date-picker/styles/colors/teal.css';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { massEditOrders } from '../../redux/cfdOrder/cfdOrderActions';
import { getFormattedTimeZoneOutput } from '../../helpers/helper';

export function MassEditOpenOrdersModal({
  orders = [], hideModal, clearRows, handleRefresh, 
}) {
  const dispatch = useDispatch();
  const [updatedOrderParams, setUpdatedOrderParams] = useState({
    timeOpened: new Date(),
  });
  const [step, setStep] = useState(1);
  const { massEditResults = [] } = useSelector((state) => state.cfdOrders);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const isBtnDisabled = Number.isNaN(Number(updatedOrderParams.tradeType));

  const handleNumberValue = (value) => value.replace(/[^\d.]/g, '').replace(/^0+(\d)/, '0$1');

  const handleChange = (e) => {
    const numberFields = ['tradeStartPrice', 'position', 'takeProfitPrice', 'stopLossPrice'];
    let { value } = e.target;
    const { name } = e.target;

    if (numberFields.includes(name)) value = handleNumberValue(value);

    setUpdatedOrderParams((prev) => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    massEditOrders({ timeOpened: new Date() });
    hideModal();
  };

  const massUpdateOrder = async () => {
    const orderIds = orders.map(({ _id }) => (_id));
    setIsBtnLoading(true);
    await dispatch(massEditOrders(
      { 
        ...updatedOrderParams, 
        orderIds, 
        isTPSLSet: !!(updatedOrderParams.takeProfitPrice || updatedOrderParams.stopLossPrice),
      },
    ));
    setIsBtnLoading(false);
    setStep(2);
    clearRows();
    setTimeout(() => {
      handleRefresh();
    }, 1500);
  };

  return step === 1 ? (
    <>
      <div className="row mb-3">
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Type</label>
          <Select
            className="form-control"
            value={updatedOrderParams.tradeType}
            onChange={handleChange}
            name="tradeType"
            style={{ height: '40px' }}
          >
            <MenuItem value={1}>Buy</MenuItem>
            <MenuItem value={0}>Sell</MenuItem>
          </Select>
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Price Open</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Price Open"
            aria-label=""
            aria-describedby=""
            value={updatedOrderParams.tradeStartPrice}
            name="tradeStartPrice"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Volume</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Volume"
            aria-label=""
            aria-describedby=""
            name="position"
            value={updatedOrderParams.position}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Time Opened</label>
          <br />
          <DatePicker 
            value={updatedOrderParams.timeOpened}
            onChange={(date) => {
              const query = {
                target: {
                  name: 'timeOpened',
                  value: date,
                },
              };
              handleChange(query);
            }}
            format="MM/DD/YYYY HH:mm:ss"
            className="bg-dark teal" 
            inputClass="form-control"
            animations={[
              opacity(), 
              transition({ from: 35, duration: 800 }),
            ]}
            plugins={[
              <TimePicker hideSeconds />,
            ]}
          />
          <div>
            { `Time zone: ${getFormattedTimeZoneOutput()}` }
          </div>
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">TP</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Take Profit"
            aria-label=""
            aria-describedby=""
            name="takeProfitPrice"
            value={updatedOrderParams.takeProfitPrice}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">SL</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Stop Loss"
            aria-label=""
            aria-describedby=""
            name="stopLossPrice"
            value={updatedOrderParams.stopLossPrice}
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <button type="button" className="btn btn-default me-2" onClick={massUpdateOrder} disabled={isBtnDisabled || isBtnLoading}>
          {
            !isBtnLoading ? 'Save' : <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          }
        </button>
        <button type="button" className="btn btn-danger" onClick={handleClose}>Cancel</button>
      </div>
    </>
  ) : (
    <div className="row mb-3">
      {
            massEditResults.map((result) => (
              <div
                key={result.id}
                style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px', 
                }}
              >
                <span>{`Order #${result.id}`}</span>
                <span style={{ color: `${result.success ? 'green' : 'red'}` }}>{result.message}</span>
              </div>
            ))
        }
    </div>
  );
};
