import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import {
  Form, FormGroup, Modal, Button, Row, Col, 
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner, faRefresh, faTimes, faPlus, 
} from '@fortawesome/free-solid-svg-icons';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import Select from 'react-select';
import {
  removeCurrencyAmountFromUserAccount, resolveWithDrawTransaction, getUserDetails, revertTransaction,
} from '../../../redux/users/userActions';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../../redux/crmUser/crmUserActions';
import { withdrawColumnsSchema } from '../../../DataTableSchemas/WithdrawalComponentSchemas';
import { resolveWithDrawFiatTransaction } from '../../../redux/externalFiatTransactions/externalFiatTransactionActions';
import { resolveWithDrawBankTransaction } from '../../../redux/externalBankTransactions/externalBankTransactionActions';
import { getCurrencyRates } from '../../../redux/currencyRate/currencyRateActions';
import { showAllCurrencies } from '../../../redux/currency/currencyActions';
import { getAllUserWithdrawals, updateIsReal, deleteTransaction } from '../../../redux/transactions/transactionActions';
import { getAccountOptionsByUserId } from '../../../redux/cfdAccount/cfdAccountActions';
import { userWithdrawalsColumns } from '../../../columnsDefaultConfig';
import { DatatableColumns, DatatableFilters } from '../../../components';

function WithdrawalComponent() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  const [currencyFilter, setCurrencyFilter] = useState([]);
  const [transactionIdFilter, setTransactionIdFilter] = useState('');
  const [amountRange, setAmountRange] = useState([0, 1000000]);
  const [inUSDRange, setInUSDRange] = useState([0, 1000000]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [transactionFilter, setTransactionFilter] = useState([]);
  const [timeInitiatedFilter, setTimeInitiatedFilter] = useState([]);
  const [isRealFilter, setIsRealFilter] = useState([]);

  const columnsStorageName = 'DataTable_user/withdrawal_columns';
  const filtersStorageName = 'DataTable_user/withdrawal_filters';
  const filterIdStorageName = 'DataTable_user/withdrawal_filter_id'; 
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);
  const [columnConfig, setColumnConfig] = useState(userWithdrawalsColumns);
  const [filters, setFilters] = useState();
  const [withdrawalCurrency, setWithdrawalCurrency] = useState('');

  const timeFilterRef = useRef(null);
  const datatableFiltersRef = useRef(null);

  const [activeInfo, setActiveInfo] = useState(null);
  const [activeComment, setActiveComment] = useState(null);

  const [removeAmountInfo, setRemoveAmountInfo] = useState('');
  const [removeAmountInfoErr] = useState('');
  const [isReal, setIsReal] = useState(3);
  const [isRealErr, setIsRealErr] = useState('');
  const [isResolved, setIsResolved] = useState(3);
  const [isResolvedErr, setIsResolvedErr] = useState('');
  const [balanceType, setBalanceType] = useState(3);
  const [balanceTypeErr, setBalanceTypeErr] = useState('');
  const [apiCall, setApiCall] = useState(false);
  const [removeCurrencyAccountSelectedErr, setRemoveCurrencyAccountSelectedErr] = useState('');
  const [accountErr, setAccountErr] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');

  const data = useSelector((state) => state?.transaction?.allUserWithdrawals);
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const currencyRates = useSelector((state) => state.currencyRates);
  const accountOptions = useSelector((state) => state.cfdAccounts.options);
  const isUserCanWithdrawalManual = permissionName && permissionName.includes('withdrawal_manual');

  const sortedData = useMemo(() => data.sort((a, b) => {
    if (a.createdAt > b.createdAt) return -1;

    if (a.createdAt < b.createdAt) return 1;

    if (a.createdAt === b.createdAt) {
      if (a.createdAt > b.createdAt) return -1;

      if (a.createdAt < b.createdAt) return 1;

      return 0;
    }

    return 0;
  }), [data]); 

  const {
    register: register2, handleSubmit: handleSubmit2, control: control2, reset: reset2, formState: { errors: errors2 },
  } = useForm();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRemoveAmountInfo('');
    setIsRealErr('');
    setIsResolvedErr('');
    setBalanceTypeErr('');
    setRemoveCurrencyAccountSelectedErr('');
    setSelectedAccount('');
    setAccountErr('');
    setIsReal(3);
    setIsResolved(3);
    setBalanceType(3);
    reset2();
    setApiCall(false);
  };
  const handleShow = () => setShow(true);

  const searchByTransactionId = ({ target: { value } }) => {
    setTransactionIdFilter(value);
    setFilters({ ...filters, transactionId: value });
  };

  const onIsRealChange = (transactionId, newIsRealValue) => {
    if (transactionId) {
      const newTransactions = [...filteredItems]; 
      const transactionIndex = newTransactions.findIndex((t) => t?._id === transactionId); 
  
      if (transactionIndex !== -1) {
        newTransactions[transactionIndex].isReal = newIsRealValue === 'true'; 
        setFilteredItems(newTransactions);
      }
    }
    dispatch(updateIsReal(transactionId, newIsRealValue));
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  useEffect(() => {
    setStoredColumnsData();

    Promise.allSettled([
      dispatch(showAllCurrencies()),
      dispatch(getCurrencyRates('USD')),
    ]);
  }, []);

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setFilters(filters);

      setAmountRange(filters.amountRange || [0, 1000000]);
      setCurrencyFilter(filters.currency || []);
      setInUSDRange(filters.isUSDRange || [0, 1000000]);
      setTransactionFilter(filters['transaction type'] || []);
      setIsRealFilter(filters['real/fake'] || []);
      setStatusFilter(filters.status || []);
    } 
  };

  const getWithdrawals = async () => dispatch(getAllUserWithdrawals(id));

  useEffect(async () => {
    setLoader(true);
    if (!data || !data.length) {
      await Promise.all([
        getWithdrawals(),
        dispatch(getAccountOptionsByUserId(id)),
      ]);
    }
    setStoredFilterData();
    setLoader(false);
  }, [id]);

  const getRateInUsdt = (coinSymbol, amount) => {
    if (currencyRates && Object.keys(currencyRates).length) {
      const totalInUsdt = parseFloat(
        parseFloat(1 / currencyRates[coinSymbol === 'USDT' ? 'USD' : coinSymbol])
        * parseFloat(amount),
      );

      if (!Number.isNaN(totalInUsdt)) {
        return totalInUsdt;
      }
      return null;
    }
    return '-';
  };

  const handleDeleteTransaction = async (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(deleteTransaction(id));
          getWithdrawals();
        } 
      });
  };

  const resolveCurrentWithDrawTransaction = async (rowId, userId, status) => {
    Swal.fire({
      title: `Are you sure you want to ${status === 1 ? 'Approve' : 'Decline'} it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = { userId, resolvedStatus: status, comment: result.value ? result.value : '' };
        await dispatch(resolveWithDrawTransaction(rowId, data));
        await dispatch(getAccountOptionsByUserId(id));
        getWithdrawals();
      }
    });
  };

  const resolveCurrentFiatWithDrawTransaction = async (rowId, userId, status) => {
    Swal.fire({
      title: `Are you sure you want to ${status === 1 ? 'Approve' : 'Decline'} it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { userId, resolvedStatus: status, additionalInfo: result.value ? result.value : '' };

        return Promise.resolve(
          dispatch(resolveWithDrawFiatTransaction(rowId, data)),
        )
          .then(
            () => dispatch(getUserDetails(id)),
          ).catch((error) => {
            // console.log(error, 'resolveCurrentWithDrawTransaction');
          });
      }
      return null;
    });
  };

  const resolveCurrentBankWithDrawTransaction = async (rowId, userId, status) => {
    Swal.fire({
      title: `Are you sure you want to ${status === 1 ? 'Approve' : 'Decline'} it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { userId, resolvedStatus: status, additionalInfo: result.value ? result.value : '' };

        return Promise.resolve(
          dispatch(resolveWithDrawBankTransaction(rowId, data)),
        )
          .then(
            () => dispatch(getUserDetails(id)), 
          ).catch((error) => {
            // console.log(error, 'resolveCurrentWithDrawTransaction');
          });
      }
      return null;
    });
  };

  const handleRevertTransaction = async (row) => {
    Swal.fire({
      title: 'Are you sure you want to Revert the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = { userId: id };
        await dispatch(revertTransaction(row, data));
        await dispatch(getAccountOptionsByUserId(id));
        getWithdrawals();
      }
    });
  };

  const removeCurrencyAmount = {
    amountForRemoveCurrency: {
      required: 'Amount is required',
      pattern: {
        value: /^[0-9]\d*(\.\d+)?$/,
        message: 'Only numbers and decimals are allowed',
      },
    },
  };

  const handleCurrencyAmountRemove = async (formData) => {
    let errorsOccured = false;

    if (!withdrawalCurrency.value) {
      setRemoveCurrencyAccountSelectedErr('Select currency of transaction');
      errorsOccured = true;
    }

    if (balanceType === 3) {
      setBalanceTypeErr('Select type of transaction');
      errorsOccured = true;
    }

    if (isResolved === 3) {
      setIsResolvedErr('Select status of transaction');
      errorsOccured = true;
    }

    if (isReal === 3) {
      setIsRealErr('Select if transaction is real or fake');
      errorsOccured = true;
    }

    if (!selectedAccount || !selectedAccount.length) {
      setAccountErr('Select account');
      errorsOccured = true;
    }

    if (!errorsOccured) {
      setApiCall(true);
      const data = {
        userId: id,
        currencyId: withdrawalCurrency.value,
        amount: formData.amountForRemoveCurrency,
        additionalInfo: removeAmountInfo,
        isReal,
        isResolved,
        selectedAccount,
        balanceType: Number(balanceType) === 0,
        addedBy: localStorage.getItem('userId').slice(1, -1),
      };

      await dispatch(removeCurrencyAmountFromUserAccount(data));
      await dispatch(getAccountOptionsByUserId(id));
      getWithdrawals();
      handleClose();
    }
  };

  const handleClear = () => {
    setAmountRange([0, 1000000]);
    setTransactionIdFilter('');
    setStatusFilter([]);
    setInUSDRange([0, 10000000]);
    setTransactionFilter([]);
    setCurrencyFilter([]);
    setIsRealFilter([]);
    setTimeInitiatedFilter([]);
    setFilters({});
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
    timeFilterRef.current.clear();
  };

  const filteredRes = useMemo(() => sortedData.filter(
    (item) => (!currencyFilter.length || currencyFilter.includes(item.currency))
        && (Number(item.amount) >= amountRange[0] && Number(item.amount) <= amountRange[1])
        && (Number(getRateInUsdt(item?.currency, item?.amount)).toFixed(2) >= inUSDRange[0] && Number(getRateInUsdt(item?.currency, item?.amount)).toFixed(2) <= inUSDRange[1])
        && (!transactionFilter.length || transactionFilter.includes(item.type))
        && (!isRealFilter.length || isRealFilter.includes(String(item.isReal)))
        && (!statusFilter.length || statusFilter.includes(String(item.isResolved)))
        && (timeInitiatedFilter && (!timeInitiatedFilter.length || timeInitiatedFilter.includes(item._id)))
        && (!transactionIdFilter.length || +transactionIdFilter === item?.customId),
  ), [currencyFilter, amountRange, inUSDRange, transactionFilter, isRealFilter, statusFilter, timeInitiatedFilter, currencyRates, transactionIdFilter, sortedData]);

  useEffect(() => {
    if (filters && Object.keys(filters).length) localStorage.setItem(filtersStorageName, JSON.stringify(filters));
  }, [filters]);

  const setCRMFilters = (filter) => {
    const {
      currency, amountRange, isUSDRange, status, type, isReal, timeOptionIds, transactionId, 
    } = filter;

    setTransactionIdFilter(transactionId);
    setAmountRange(amountRange);
    setCurrencyFilter(currency);
    setInUSDRange(isUSDRange);
    setTransactionFilter(type);
    setIsRealFilter(isReal);
    setStatusFilter(status);
    setFilters({
      transactionId,
      'transaction type': type,
      'real/fake': isReal,
      currency,
      status,
      amountRange,
      isUSDRange,
      timeOptionIds,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
    timeFilterRef.current.set(timeOptionIds);
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    const data = {
      name,
      crmUserId,
      pathname: `${pathname}/withdrawal`,
      transactionId: filters.transactionId || '',
      currency: filters.currency || [],
      amountRange: filters.amountRange?.length ? filters.amountRange : [0, 1000000],
      isUSDRange: filters.isUSDRange?.length ? filters.isUSDRange : [0, 1000000],
      type: filters['transaction type'] || [],
      isReal: filters['real/fake'] || [],
      status: filters.status || [],
      timeOptionIds: filters.timeOptionIds || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters.currency || [],
        transactionId: filters.transactionId || '',
        amountRange: filters.amountRange?.length ? filters.amountRange : [0, 1000000],
        isUSDRange: filters.isUSDRange?.length ? filters.isUSDRange : [0, 1000000],
        type: filters['transaction type'] || [],
        isReal: filters['real/fake'] || [],
        status: filters.status || [],
        timeOptionIds: filters.timeOptionIds || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const storeColumnConfig = (data) => {    
    setColumnConfig(data);
    localStorage.setItem(columnsStorageName, JSON.stringify(data));
  };

  const withdrawColumns = withdrawColumnsSchema(
    amountRange,
    transactionIdFilter,
    searchByTransactionId,
    setAmountRange,
    currencyFilter,
    setCurrencyFilter,
    currencies,
    inUSDRange,
    setInUSDRange,
    getRateInUsdt,
    setTransactionFilter,
    transactionFilter,
    statusFilter,
    setStatusFilter,
    activeInfo,
    activeComment,
    setActiveInfo,
    setActiveComment,
    permissionName,
    resolveCurrentWithDrawTransaction,
    handleRevertTransaction,
    resolveCurrentBankWithDrawTransaction,
    resolveCurrentFiatWithDrawTransaction,
    onIsRealChange,
    columnConfig,
    isRealFilter,
    setIsRealFilter,
    setTimeInitiatedFilter,
    timeFilterRef,
    filtersStorageName,
    filters,
    setFilters,
    sortedData,
    updateIsReal,
    handleDeleteTransaction,
  );

  const handleWithdrawalCurrencyChange = (withdrawalCurrencyChange) => {
    setWithdrawalCurrency(withdrawalCurrencyChange);
  };

  const colourStyles = {
    control: (styles, { isDisabled, isSelected }) => ({
      ...styles,
      background: isDisabled ? '#aaa' : '#374057',
      color: isDisabled ? '#aaa' : 'fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      border: '1px solid #374057',
      boxShadow: isSelected ? 'none' : 'none',
      borderColor: isSelected ? '#374057' : '#374057',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#374057',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#16202e'
        : isSelected
          ? '#16202e'
          : undefined,
      color: 'fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      zIndex: 1,
      '&:hover': {
        background: isDisabled ? '#aaa' : '#16202e',
      },
    }),
  };

  return (
    <div>
      <div>
        <FormGroup role="form">
          <Modal show={show} onHide={handleClose} className="widthdrawal-modal">
            <Modal.Header closeButton>
              <Modal.Title className="text-white">ADD WITHDRAWAL</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit2(handleCurrencyAmountRemove)} className="account-balance-currency me-0">
              <Modal.Body>
                <div className="withdrawal-data">
                  <Row>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Amount</label>
                        <div className="input-wrapper">
                          <input type="text" className="form-control" {...register2('amountForRemoveCurrency', removeCurrencyAmount?.amountForRemoveCurrency)} name="amountForRemoveCurrency" defaultValue="" control={control2} />
                          {errors2?.amountForRemoveCurrency && <span className="errMsg">{errors2?.amountForRemoveCurrency?.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Currency</label>
                        <div className="input-wrapper">
                          <Select
                            styles={colourStyles}
                            name="removeCurrencyAccountSelected"
                            options={currencies && currencies.length > 0 && currencies.map((currency) => ({
                              value: currency._id,
                              label: `${currency?.name}  (${currency?.symbol})`,
                            }))}
                            isSearchable
                            onChange={handleWithdrawalCurrencyChange}
                          />
                          {removeCurrencyAccountSelectedErr ? (<span className="errMsg">{removeCurrencyAccountSelectedErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Account</label>
                        <div className="input-wrapper">
                          <Form.Select name="account" value={selectedAccount} onChange={({ target: { value } }) => setSelectedAccount(value)}>
                            <option value="">Select Account</option>
                            { 
                                  accountOptions.map(({ value, label }) => (
                                    <option key={value} value={value}>{label}</option>
                                  ))
                                }
                          </Form.Select>
                          {accountErr ? (<span className="errMsg">{accountErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Type</label>
                        <div className="input-wrapper">
                          <Form.Select name="balanceType" value={balanceType} onChange={(event) => setBalanceType(event.target.value)}>
                            <option value="3">Select Type</option>
                            <option value="0">Balance</option>
                            {/* <option value="1">Credit</option> */}
                          </Form.Select>
                          {balanceTypeErr ? (<span className="errMsg">{balanceTypeErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Status</label>
                        <div className="input-wrapper">
                          <Form.Select name="isResolved" value={isResolved} onChange={(event) => setIsResolved(event.target.value)}>
                            <option value="3">Select Type</option>
                            <option value="0">Pending</option>
                            <option value="1">Approved</option>
                            <option value="2">Declined</option>
                          </Form.Select>
                          {isResolvedErr ? (<span className="errMsg">{isResolvedErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Real</label>
                        <div className="input-wrapper">
                          <Form.Select name="isReal" value={isReal} onChange={(event) => setIsReal(event.target.value)}>
                            <option value="3">Select Transactions </option>
                            <option value="1">Real</option>
                            <option value="0">Fake</option>
                          </Form.Select>
                          {isRealErr ? (<span className="errMsg">{isRealErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Info</label>
                        <div className="input-wrapper">
                          <input type="text" className="form-control" name="removeAmountInfo" value={removeAmountInfo} onChange={(event) => setRemoveAmountInfo(event.target.value)} />
                          {removeAmountInfoErr ? (<span className="errMsg">{removeAmountInfoErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                {apiCall
                  ? (
                    <Button variant="primary" style={{ padding: '8px 40px' }}>
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    </Button>
                  )
                  : (
                    <Button variant="primary" type="submit">
                      Save Changes
                    </Button>
                  )}
              </Modal.Footer>
            </Form>
          </Modal>
        </FormGroup>

        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {isUserCanWithdrawalManual && (
                <button type="button" className="btn-primary_light" onClick={handleShow}>
                  <FontAwesomeIcon icon={faPlus} size="sm" />
                  Add New
                </button>
              )}
            </div>
            <div className="secondary_btn-row">
              {
              crmFilters && (
                <DatatableFilters
                  ref={datatableFiltersRef}
                  filters={crmFilters}
                  setFilters={setCRMFilters}
                  createFilter={createUserCRMFilter}
                  deleteFilter={deleteUserCRMFilter}
                  updateFilter={updateUserCRMFilter}
                  storageKey={filterIdStorageName}
                  pathname={`${pathname}/withdrawal`}
                />
              )
            }
              <button type="button" className="btn-secondary_dark iconed" onClick={handleClear}>
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>
              <button type="button" className="btn-secondary_dark iconed" onClick={getWithdrawals}>
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
            <div className="secondary_btn-row">
              <DatatableColumns columnConfig={columnConfig} setColumns={storeColumnConfig} />
            </div>
          </div>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
              (sortedData && sortedData.length) ? (
                <DataTable
                  columns={withdrawColumns}
                  data={filteredRes}
                  paginationRowsPerPageOptions={[10, 50, 100, 500]}
                  pagination
                  fixedHeader
                  persistTableHead
                  highlightOnHover
                  theme="solarizedd"
                />
              ) : (
                <div className="no-tbl-data">No Transactions Found!</div>
              )
            }
        </div>
        <br />
      </div>
    </div>
  );
}

export default WithdrawalComponent;
