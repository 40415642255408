import React, { useState, useEffect, useRef } from 'react';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import Icon from 'react-multi-date-picker/components/icon';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import './style.css';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { useClickOutside } from '../../hooks/useClickOutside';

export function ModalFilters({
  data = [], filters, setFilters, tabName, searchParams, usersFilters, setUserFilters, isDateOptions = false,
}) {
  const ModalFilterRef = useRef(null);
  const [showFilter, setShowFilter] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filtredItems, setFilteredItems] = useState([]);
  const [datePickerValue, setDatePickerValue] = useState(new DateObject());
  const [prevCustomFilterId, setPrevCustomFilterId] = useState();
  const datePickerRef = useRef();
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [dateWithTime, setDateWithTime] = useState([]);
  const [timePickerValue, setTimePickerValue] = useState(null);
  const [firstDatePickerValue, setFirstDatePickerValue] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const applyBtnDisable = (datePickerValue.length === 2 || !Array.isArray(datePickerValue)) && showTimePicker;
  
  useClickOutside({ onClickOutside: () => { setShowFilter(false); setOpenModal(false); }, ref: ModalFilterRef });

  const handleFilter = () => {
    const filteredResult = [];

    data.forEach((item) => {
      searchParams.params.forEach((param) => {
        const contained = filteredResult.filter((res) => res._id === item._id);
        if (item[param]?.toLowerCase().includes(filterText.toLowerCase()) && !contained.length) filteredResult.push(item);
      });
    });

    if (filteredResult.length) {
      setFilteredItems([...filteredResult]);
    }
  };

  const setOptionName = (item) => {
    let optionName = '';

    searchParams.optionsName.forEach((name) => {
      optionName += ` ${item[name]}`; 
    });

    return optionName;
  };
    
  const handleSelectAll = () => {
    const filters = data.map((item) => item[searchParams.id]);
    setFilters(filters);
    setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: filters });
  };
    
  const handleClearAll = () => {
    setFilters([]);
    setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: [] });
    setDatePickerValue(new DateObject());
    setPrevCustomFilterId();
    setShowTimePicker(false);
    setOpenModal(false);
  };

  const handleClick = (selectedItem) => {
    if (filters.includes(selectedItem[searchParams.id])) {
      const filtersWithRemoved = filters.filter((item) => item !== selectedItem[searchParams.id]);
      setFilters(filtersWithRemoved);
      setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: filtersWithRemoved });
    } else {
      const filtersWithAdded = [...filters, selectedItem[searchParams.id]];
      setFilters(filtersWithAdded);
      setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: filtersWithAdded });
    } 
  };

  const dateArrayCreator = (fromDate, toDate, toTimeValue) => {
    const startDate = new Date(fromDate);
    startDate.setHours(0, 0, 0, 0);
    const [hours, minutes] = toTimeValue.split(':').map(Number);
    const endDate = new Date(toDate);
    endDate.setHours(hours, minutes, 0, 0);
    const dateArray = [startDate, endDate];
    const convertedDateArray = dateArray.map((date) => date.toISOString());
    return convertedDateArray;
  };

  const createDateArrayWrapper = (fromDateValue, toDateValue) => (toTimeValue) => dateArrayCreator(fromDateValue, toDateValue, toTimeValue);

  const handleSetDateRange = (dateRange) => {
    setDatePickerValue(dateRange);
    const _id = dateRange.join('||');
    setPrevCustomFilterId(_id);
        
    const filtersWithAdded = prevCustomFilterId ? [...filters.filter((item) => item !== prevCustomFilterId), _id] : [...filters, _id];
    setFilters(filtersWithAdded);
    setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: filtersWithAdded });
    datePickerRef.current.closeCalendar();
    setShowTimePicker(false);
    setOpenModal(false);
    setDatePickerValue(new DateObject());
  };

  function sortDatesArray(dateArray, value) {
    if (dateArray.length === 2 && value.length === 2) {
      const [firstElement, secondElement] = dateArray;
      const [fromDate, fromTime] = firstElement.split(' ');
      const [toDate, toTime] = secondElement.split(' ');

      const sameDate = (fromDate === firstDatePickerValue && toDate === firstDatePickerValue);
      const notSameFromDate = (fromDate !== firstDatePickerValue && toDate === firstDatePickerValue);
      const notSameToDate = (fromDate === firstDatePickerValue && toDate !== firstDatePickerValue);

      const sameTime = (fromTime === timePickerValue && toTime === timePickerValue);
      const notSameFromTime = (fromTime !== timePickerValue && toTime === timePickerValue);
      const notSameToTime = (fromTime === timePickerValue && toTime !== timePickerValue);

      const dateArrayWithFixedRange = createDateArrayWrapper(fromDate, toDate);

      if ((sameDate && sameTime) || (sameDate && notSameToTime)) {
        return dateArrayWithFixedRange(toTime);
      }
      if (sameDate && notSameFromTime) {
        return dateArrayWithFixedRange(fromTime);
      }

      if ((notSameFromDate && sameTime) || (notSameFromDate && notSameToTime)) {
        return dateArrayWithFixedRange(toTime);
      }
      if (notSameFromDate && notSameFromTime) {
        return dateArrayWithFixedRange(fromTime);
      }

      if ((notSameToDate && sameTime) || (notSameToDate && notSameToTime)) {
        return dateArrayWithFixedRange(toTime);
      }
      if (notSameToDate && notSameFromTime) {
        return dateArrayWithFixedRange(fromTime);
      }
    }
    return dateArray;
  }

  useEffect(() => {
    if (!openModal) return;

    if (!Array.isArray(datePickerValue)) {
      const formattedMonth = datePickerValue.month.number <= 9 ? `0${datePickerValue.month.number}` : datePickerValue.month.number;
      const formattedDay = datePickerValue.day <= 9 ? `0${datePickerValue.day}` : datePickerValue.day;
      const timeString = `${datePickerValue.hour}:${datePickerValue.minute}`;
      const dateString = `${formattedMonth}/${formattedDay}/${datePickerValue.year}`;
      setTimePickerValue(timeString);
      setFirstDatePickerValue(dateString);
    }

    if (datePickerValue.length === 1) {
      const [dateObject] = datePickerValue;
      const formattedMonth = dateObject.month.number <= 9 ? `0${dateObject.month.number}` : dateObject.month.number;
      const formattedDay = dateObject.day <= 9 ? `0${dateObject.day}` : dateObject.day;
      const timeString = `${dateObject.hour}:${dateObject.minute}`;
      const dateString = `${formattedMonth}/${formattedDay}/${dateObject.year}`;
      setTimePickerValue(timeString);
      setFirstDatePickerValue(dateString);
    }
  }, [datePickerValue, openModal]);

  const datePickerHandler = (value, validatedValue) => {
    setDatePickerValue(value);
    if (validatedValue.length > 1) setShowTimePicker(true);
    const formattedTimeDiapason = sortDatesArray(validatedValue, value);
    setDateWithTime(formattedTimeDiapason);
  };

  useEffect(() => {
    setFilteredItems(data);
  }, [data]);

  useEffect(() => {
    handleFilter();
  }, [filterText]);

  return (
    <div className="modal-filter__container" ref={ModalFilterRef}>
      <div
        className="modal-filter__tab-name" 
        onClick={() => setShowFilter(!showFilter)}
      >
        {tabName}
        {!showFilter ? <ListIcon size="small" /> : <CloseIcon size="small" />}
      </div>
      {showFilter ? (
        <div className="leads-modal-filter">
          <FontAwesomeIcon
            icon={faTimes}
            size="lg"
            color="#6E7F95"
            onClick={() => { setShowFilter(false); }}
          />
          <input
            type="text"
            autoComplete="off"
            placeholder="Search"
            className="leads-search-bar-filter"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button type="button" onClick={() => handleSelectAll()}>Check all</button>
          <button type="button" onClick={() => handleClearAll()}>Uncheck all</button>
          <ul className="modal-filter__list">
            {filtredItems.map((item) => (
              <li
                className="modal-filter__option"
                style={{ backgroundColor: filters.includes(item._id) ? '#061426' : '' }}
                onClick={() => handleClick(item)}
                key={item._id}
              >
                {filters.includes(item[searchParams.id]) ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color="#6E7F95"
                  />
                ) : null}
                <div className="modal-filter__option-name">{setOptionName(item)}</div>
              </li>
            ))}
            {isDateOptions 
              && (
              <li 
                className="modal-filter__option"
                onClick={() => setOpenModal(true)}
                style={{ backgroundColor: filters.filter((item) => !filtredItems.includes(item[searchParams.id])).length ? '#061426' : '' }}
              >                         
                <DatePicker
                  className={`${applyBtnDisable ? 'bg-dark custom-datepicker-dropdown' : 'bg-dark'}`}
                  value={datePickerValue} 
                  ref={datePickerRef}
                  onChange={(value, { validatedValue }) => datePickerHandler(value, validatedValue)}
                  range
                  rangeHover
                  format="MM/DD/YYYY HH:mm"
                  plugins={[
                    <TimePicker 
                      position="bottom" 
                      hideSeconds 
                      disabled={!applyBtnDisable}
                    />,
                  ]}
                  render={(value, openCalendar) => (
                    <div
                      onClick={openCalendar}
                    >
                      {filters.filter((item) => !filtredItems.includes(item[searchParams.id])).length ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#6E7F95"
                          style={{ marginRight: '5px' }}
                        />
                      ) : null}
                      Custom
                      <Icon style={{ height: '20px' }} />
                    </div>
                  )}
                >
                  <div>
                    <button type="button" onClick={handleClearAll} className="btn btn-danger btn-sm m-2">
                      Clear
                    </button>
                    <button
                      type="button"
                      onClick={() => handleSetDateRange(dateWithTime)}
                      className="btn btn-success btn-sm me-1 p-1" 
                      disabled={!applyBtnDisable}
                    >
                      Apply
                    </button>
                  </div>
                </DatePicker>
              </li>
              )}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
