import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import { GET_SAVING_INVESTMENTS, DELETE_SAVING_INVESTMENT } from './savingInvestmentsTypes';

export const getSavingInvestments = ({ page, rowsPerPage, filters = {} }) => async (dispatch) => {
  try {
    let url = `/api/saving-investment?page=${page}&limit=${rowsPerPage}`;

    if (filters && filters['release date']) {
      url += `&releaseTime=${filters['release date']}`;
    }

    if (filters && filters['start date']) {
      url += `&createdAt=${filters['start date']}`;
    }

    const res = await apiHelper('get', url);

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_SAVING_INVESTMENTS,
        payload: res.data.savingInvestments,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const getUserSavingInvestments = (id, { page, rowsPerPage, filters }) => async (dispatch) => {
  try {
    let url = `/api/saving-investment/user/${id}?page=${page}&limit=${rowsPerPage}`;

    if (filters && filters['release date']) {
      url += `&releaseTime=${filters['release date']}`;
    }

    if (filters && filters['start date']) {
      url += `&createdAt=${filters['start date']}`;
    }

    const res = await apiHelper('get', url);

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_SAVING_INVESTMENTS,
        payload: res.data.savingInvestments,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteSavingInvestment = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/saving-investment/${id}`, '');

    if (res && res.data && res.data.success) {
      await dispatch({
        type: DELETE_SAVING_INVESTMENT,
        payload: id,
      });

      toast.success('Record successfully deleted!', {
        autoClose: 1000,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};
