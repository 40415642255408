import {
  Link, NavLink, useLocation, useHistory, 
} from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import React, {
  useEffect, useState, useRef, useMemo, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faUsers,
  faArrowsRotate,
  faFolderOpen,
  faEnvelope,
  faGauge,
  faBell,
  faMoneyCheck,
  faClock,
  faIdCard,
  faExchange,
  faMoneyBill,
  faTrademark,
  faBars,
  faTimes,
  faUserLock,
  faList,
  faTrash,
  faComment,
  faCheckCircle,
  faBriefcase,
  faNetworkWired,
  faCalendar,
  faFileContract,
  faArrowTrendUp,
  faPenToSquare,
  faClockRotateLeft,
  faPhoneSquare,
  faComputer,
  faCommentSms,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import { getCRMUser } from '../redux/crmUser/crmUserActions';
import { getPendingWithdrawals, getPendingDeposits } from '../redux/transactions/transactionActions';
import { setSideBarMounted } from '../redux/staticComponent/staticComponentActions';
import { getChats } from '../redux/chat/chatActions';
import { setNavigator } from '../services/navigatorService';

function NavigationMenu() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [path, setPath] = useState('/leads');
  const [activeSidebar, setActiveSidebar] = useState(true);

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const chats = useSelector((state) => state.chatsInfo.chats);
  const allPendingWithdrawalsCount = useSelector(
    (state) => state?.transaction?.pendingWithdrawals?.pendingWithdrawals?.[0]?.total_count,
  );
  const allPendingDeposits = useSelector(
    (state) => state?.transaction?.pendingDeposits?.pendingDeposits?.[0]?.total_count,
  );
  const userData = useSelector((state) => state?.crmUser?.crmUserInfo);
  const isSideBarMounted = useSelector((state) => state.staticComponents.isSideBarMounted);

  const isUserCanSeePsp = permissionName && permissionName.includes('psp');
  const isUserCanSeePspOther = permissionName && permissionName.includes('psp_other');
  const isUserCanSeeSymbolSettings = permissionName && permissionName.includes('symbol_settings_management');
  const isUserCanSeeBankAccount = permissionName && permissionName.includes('bank_account');
  const isUserCanSeeAdminWallets = permissionName && permissionName.includes('admin_wallets');
  const isUserCanSeeDeposits = permissionName && permissionName.includes('deposits');
  const isUserCanSeeWithdrawals = permissionName && permissionName.includes('withdrawals');
  const isUserCanSeeKycAml = permissionName && permissionName.includes('kyc_aml');
  const isUserCanSeeRoles = permissionName && permissionName.includes('roles_management');
  const isUserCanSeePermissions = permissionName && permissionName.includes('permissions_management');
  const isUserCanManageLeverages = permissionName && permissionName.includes('leverage_management');
  const isUserCanSeeAffiliates = permissionName && permissionName.includes('affiliates');
  const isUserCanSeeEmailTemplates = permissionName && permissionName.includes('email_templates');
  const isUserCanSeeWithdrawalMethods = permissionName && permissionName.includes('withdrawal_methods');
  const isUserCanSeeSmsServices = permissionName && permissionName.includes('sms_services');
  const isUserCanSeeSmtpPresets = permissionName && permissionName.includes('smtp_presets');
  const isUserCanSeeVoIP = permissionName && permissionName.includes('voip');
  const isUserCanSeeStatuses = permissionName && permissionName.includes('statuses');
  const isUserCanSeeSecurity = permissionName && permissionName.includes('security');
  const isUserCanSeeSaving = permissionName && permissionName.includes('saving_management');
  const isUserCanSeeTradeSettings = permissionName && permissionName.includes('trade_management');
  const isUserCanSeeCrmUsers = permissionName && permissionName.includes('crm_users_management');
  const isUserCanSeeAffiliatesDashboard = permissionName && permissionName.includes('crm_affiliates_dashboard');
  const isUserCanSeeCrmUsersReport = permissionName && permissionName.includes('crm_users_report');
  const isUserCanSeeClientsReport = permissionName && permissionName.includes('clients_report');
  const isUserCanSeeNetworks = permissionName && permissionName.includes('networks');
  const isUserCanSeeDeletedTransactions = permissionName && permissionName.includes('delete_transaction');
  const isUserCanSeeFinanceBlock = permissionName && (permissionName.includes('delete_transaction') || permissionName.includes('deposits') || permissionName.includes('withdrawals'));
  const isUserCanSeeDashboard = permissionName && permissionName.includes('dashboard');
  const isUserCanSeeCfdAccounts = permissionName && permissionName.includes('cfd_accounts');
  const isUserCanSeeOpenOrders = permissionName && permissionName.length > 0 && permissionName.includes('open_orders');
  const isUserCanSeeOrdersHistory = permissionName && permissionName.length > 0 && permissionName.includes('orders_history');
  const isUserCanSeeClients = permissionName && permissionName.length > 0 && permissionName.includes('clients');
  const isUserCanSeeLeads = permissionName && permissionName.length > 0 && permissionName.includes('leads');
  const isUserCanSeeVariables = permissionName && permissionName.includes('variables');
  const isSuperUser = userData?.role?.name === 'SuperAdmin';

  const messageCounter = useMemo(() => {
    let count = 0;

    if (Object.keys(chats).length) {
      Object.keys(chats).forEach((id) => {
        chats[id].forEach((chat) => {
          chat.messages.forEach((message) => {
            if (message.sender !== id && !message.isRead) count += 1;
          });
        });
      });
    }

    return count;
  }, [chats]);
  
  const tabRef = useRef(null);

  const { pathname } = useLocation();

  useEffect(() => {
    setNavigator(history);
  }, [history]);

  const checkScreen = () => {
    if (window.innerWidth < 768 === true) {
      setActiveSidebar(false);
    } else {
      setActiveSidebar(true);
    }
  };

  useEffect(() => {
    checkScreen();
    const { pathname } = window.location;
    setPath(pathname);
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const uId = data?._id;

    if (!isSideBarMounted) {
      Promise.allSettled([
        dispatch(getPendingWithdrawals()),
        dispatch(getPendingDeposits()),
        dispatch(getCRMUser(uId, true)),
        dispatch(getChats(uId)),
      ]);

      dispatch(setSideBarMounted(true));
    }
  }, [isSideBarMounted]);

  const showSidebar = () => {
    setActiveSidebar(!activeSidebar);
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const isTabActive = (match, location, tab) => {
    const isActive = match || location.pathname === tab;
    if (isActive) {
      setActiveTab(tab);
    }
    return isActive;
  };

  useEffect(() => {
    if (!isDropdownOpen && activeTab && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [activeTab, isDropdownOpen]);

  return (
    <>
      {activeSidebar ? (
        <div className="sidebar-is-active" />
      ) : (
        null
      )}
      <div
        className={
          activeSidebar
            ? 'sidebar left-sidebar-fix show-sidenav'
            : 'sidebar left-sidebar-fix hide-sidenav'
        }
        style={{ paddingBottom: '20px' }}
      >
        <div style={{ position: 'relative' }}>
          <div className="menu-toggle">
            {activeSidebar ? (
              <FontAwesomeIcon
                icon={faTimes}
                className="text-white sidebar-icon-hide"
                onClick={() => showSidebar()}
              />
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                className="text-white sidebar-icon-hide"
                onClick={() => showSidebar()}
              />
            )}
          </div>
        </div>
        <div className="admin-panel-btns">
          <nav className="w-100">
            {isUserCanSeeDashboard && (
              <div className="menu-padding">
                <Link
                  className={`admin-nav-link ${
                    pathname === '/' ? 'active' : 'unactive'
                  }`}
                  to="/"
                >
                  <FontAwesomeIcon icon={faGauge} className="me-2" />
                  Dashboard
                </Link>
              </div>
            )}
            {isUserCanSeeLeads && (
              <NavLink to="/leads" className="admin-nav-link sub-menu-padding">
                <FontAwesomeIcon icon={faUsers} className="me-2" />
                Leads
              </NavLink>
            )}
            {isUserCanSeeClients && (
              <NavLink to="/users" className="admin-nav-link sub-menu-padding">
                <FontAwesomeIcon icon={faUsers} className="me-2" />
                Clients
              </NavLink>
            )}
            <NavLink to="/chatbox" className="admin-nav-link d-flex  align-items-center">
              <FontAwesomeIcon icon={faComment} className="me-2" />
              Chat
              {!!messageCounter && (<div className="status-bubble">{messageCounter}</div>)}
            </NavLink>
            <NavLink to="/calendar" className="admin-nav-link sub-menu-padding">
              <FontAwesomeIcon icon={faCalendar} className="me-2" />
              Calendar
            </NavLink>
            {(isUserCanSeeCrmUsers || isUserCanSeeRoles || isUserCanSeePermissions) && (
              <div className="menu-padding pb-3">
                <span className="menutitle">User Managment</span>
                {isUserCanSeeCrmUsers && (
                  <NavLink
                    className="admin-nav-link d-flex  align-items-center"
                    to="/crm-users"
                  >
                    <FontAwesomeIcon icon={faUsers} className="me-2" />
                    <span>CRM Users</span>
                  </NavLink>
                )}
                {isUserCanSeeCrmUsers && (
                  <Link
                    className={`admin-nav-link ${
                      pathname === '/hierarchy' ? 'active' : 'unactive'
                    }`}
                    to="/hierarchy"
                  >
                    <FontAwesomeIcon icon={faBell} className="me-2" />
                    Hierarchy
                  </Link>
                )}
                {isUserCanSeeRoles && (
                  <Dropdown className="user-dropdown">
                    <Dropdown.Toggle show={isDropdownOpen} onToggle={handleDropdownToggle} variant="success" id="dropdown-basic">
                      <span className="admin-nav-link d-flex  align-items-center">
                        <FontAwesomeIcon icon={faBriefcase} className="me-2" /> 
                        {' '}
                        <span>Roles</span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu show={!!(path === '/roles' || path === '/add-role')}>
                      <NavLink to="/roles" className="admin-nav-link sub-menu-padding">
                        <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                        Roles Details
                      </NavLink>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {isUserCanSeePermissions && (
                  <Dropdown className="user-dropdown">
                    <Dropdown.Toggle show={isDropdownOpen} onToggle={handleDropdownToggle} variant="success" id="dropdown-basic">
                      <span className="admin-nav-link d-flex align-items-center">
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        <span>Permissions</span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu show={!!(path === '/permissions' || path === '/add-permission' || path === '/permissions-modules' || path === '/add-permissions-module')}>
                      <NavLink to="/permissions" className="admin-nav-link sub-menu-padding">
                        <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                        Permissions Details
                      </NavLink>
                      <NavLink to="/add-permission" className="admin-nav-link sub-menu-padding">
                        <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                        Add Permissions
                      </NavLink>
                      <NavLink to="/permissions-modules" className="admin-nav-link sub-menu-padding">
                        <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                        Permissions Modules Detail
                      </NavLink>
                      <NavLink to="/add-permissions-module" className="admin-nav-link sub-menu-padding">
                        <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                        Add Permissions Module
                      </NavLink>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            )}
            {isUserCanSeeFinanceBlock && (
            <div className="menu-padding pb-3">
              <span className="menutitle">Finance</span>
              {isUserCanSeeKycAml && (
                <NavLink
                  className="admin-nav-link d-flex  align-items-center"
                  to="/kyc-aml"
                >
                  <FontAwesomeIcon icon={faIdCard} className="me-2" />
                  <span>KYC/AML</span>
                </NavLink>
              )}
              {isUserCanSeeDeposits && (
                <NavLink
                  className="admin-nav-link d-flex  align-items-center"
                  to="/external-transactions"
                >
                  <FontAwesomeIcon icon={faExchange} className="me-2" />
                  <span>Deposits</span>
                  {allPendingDeposits && (<div className="status-bubble">{allPendingDeposits}</div>)}
                </NavLink>
              )}
              {isUserCanSeeWithdrawals && (
                <NavLink
                  className="admin-nav-link d-flex  align-items-center"
                  to="/withdrawals"
                >
                  <FontAwesomeIcon icon={faClock} className="me-2" />
                  <span>Withdrawals</span>
                  {allPendingWithdrawalsCount && (<div className="status-bubble">{allPendingWithdrawalsCount}</div>)}
                </NavLink>
              )}
              {isUserCanSeeDeletedTransactions && (
                <NavLink className="admin-nav-link d-flex  align-items-center" to="/deleted-transactions">
                  <FontAwesomeIcon icon={faTrash} className="me-2" />
                  <span>Deleted Transactions</span>
                </NavLink>
              )}
            </div>
            )}
            {(isUserCanSeeCfdAccounts || isUserCanSeeSymbolSettings || isUserCanSeeSaving || isUserCanSeeOrdersHistory || isUserCanSeeOrdersHistory) && (
              <div className="menu-padding pb-3">
                <span className="menutitle">Trading Management</span>
                {isUserCanSeeCfdAccounts && (
                  <Link 
                    className={`admin-nav-link ${
                      pathname === '/cfd-accounts' ? 'active' : 'unactive'
                    }`}
                    to="/cfd-accounts"
                  >
                    <FontAwesomeIcon icon={faFileContract} className="me-2" />
                    Cfd Accounts  
                  </Link>  
                )}
                {isUserCanSeeOpenOrders && (
                  <NavLink 
                    to="/open-orders" 
                    className={`admin-nav-link ${pathname === '/open-orders' ? 'active' : 'unactive'}`}
                    isActive={(match, location) => isTabActive(match, location, '/open-orders')}
                  >
                    <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                    Open Orders
                  </NavLink>
                )}
                {isUserCanSeeOrdersHistory && (
                  <NavLink 
                    to="/orders-history" 
                    className={`admin-nav-link ${pathname === '/orders-history' ? 'active' : 'unactive'}`}
                    isActive={(match, location) => isTabActive(match, location, '/orders-history')}
                  >
                    <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                    Orders History
                  </NavLink>
                )}
                {isUserCanSeeSymbolSettings && (
                  <NavLink
                    id="symbol-settings"
                    className={`admin-nav-link ${pathname === '/symbol-settings' ? 'active' : 'unactive'}`}
                    to="/symbol-settings"
                    isActive={(match, location) => isTabActive(match, location, '/symbol-settings')}
                    innerRef={tabRef}
                  >
                    <FontAwesomeIcon icon={faMoneyCheck} className="me-2" />
                    <span>Symbol Settings</span>
                  </NavLink>
                )}
                {isUserCanSeeSaving && (
                  <Dropdown className="user-dropdown">
                    <Dropdown.Toggle show={isDropdownOpen} onToggle={handleDropdownToggle} variant="success" id="dropdown-basic">
                      <span className="admin-nav-link d-flex  align-items-center">
                        <FontAwesomeIcon icon={faArrowTrendUp} />
                        <span>Saving</span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      show={
                        !!(path === '/saving' || path === '/saving-history')
                      }
                    >
                      <NavLink to="/saving" className="admin-nav-link sub-menu-padding">
                        <FontAwesomeIcon icon={faPenToSquare} className="me-2" />
                        Edit Saving
                      </NavLink>
                      <NavLink to="/saving-history" className="admin-nav-link sub-menu-padding">
                        <FontAwesomeIcon icon={faClockRotateLeft} className="me-2" />
                        Saving History
                      </NavLink>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div> 
            )}
            {(isUserCanSeeAffiliatesDashboard || isUserCanSeeClientsReport || isUserCanSeeCrmUsersReport) && (
              <div className="menu-padding pb-3">
                <text className="menutitle">Reports</text>
                {isUserCanSeeAffiliatesDashboard && (
                  <Link
                    className="admin-nav-link"
                    to="/affiliates-dashboard"
                  >
                    <FontAwesomeIcon icon={faList} className="me-2" />
                    Affiliates Dashboard
                  </Link>
                )}
                {isUserCanSeeClientsReport && (
                  <Link
                    className={`admin-nav-link ${
                      pathname === '/client-activity' ? 'active' : 'unactive'
                    }`}
                    to="/client-activity"
                  >
                    <FontAwesomeIcon icon={faList} className="me-2" />
                    Activity (Clients)
                  </Link>
                )}
                {isUserCanSeeCrmUsersReport && (
                  <Link
                    className={`admin-nav-link ${
                      pathname === '/crm-activity' ? 'active' : 'unactive'
                    }`}
                    to="/crm-activity"
                  >
                    <FontAwesomeIcon icon={faList} className="me-2" />
                    Activity (CRM Users)
                  </Link>
                )}
              </div>
            )}
            <div className="menu-padding pb-3">
              <span className="menutitle">General Settings</span>
              <Link
                className={`admin-nav-link ${
                  pathname === '/notifications' ? 'active' : 'unactive'
                }`}
                to="/notifications"
              >
                <FontAwesomeIcon icon={faBell} className="me-2" />
                Notifications
              </Link>
              {isUserCanSeeSecurity && (
                <NavLink className="admin-nav-link" to="/whitelist-ip">
                  <FontAwesomeIcon icon={faUserLock} className="me-2" />
                  <span>Security</span>
                </NavLink>
              )}              
              {isUserCanSeePsp && (
                <Dropdown className="user-dropdown">
                  <Dropdown.Toggle show={isDropdownOpen} onToggle={handleDropdownToggle} variant="success" id="dropdown-basic">
                    <span className="admin-nav-link d-flex  align-items-center">
                      <FontAwesomeIcon icon={faMoneyBill} className="me-2" />
                      <span>PSP</span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    show={
                      !!(path === '/bank-accounts' || path === '/admin-addresses' || path === '/psp-others')
                    }
                  >
                    {isUserCanSeeBankAccount && (
                      <NavLink className="admin-nav-link" to="/bank-accounts">
                        <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                        Bank Accounts
                      </NavLink>
                    )}
                    {isUserCanSeeAdminWallets && (
                      <NavLink className="admin-nav-link" to="/admin-addresses">
                        <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                        Admin Wallets
                      </NavLink>
                    )}
                    {isUserCanSeePspOther && (
                      <NavLink className="admin-nav-link" to="/psp-others">
                        <FontAwesomeIcon icon={faMinusCircle} className="me-2" />
                        PSP Others
                      </NavLink>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {isUserCanSeeWithdrawalMethods && (
                <NavLink to="/withdrawal-methods" className="admin-nav-link sub-menu-padding">
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                  <span>Withdrawal Methods</span>
                </NavLink>
              )}
              {isUserCanSeeStatuses && (
                <NavLink className="admin-nav-link d-flex align-items-center" to="/statuses">
                  <FontAwesomeIcon icon={faFolderOpen} className="me-2" />
                  <span>Statuses</span>
                </NavLink>
              )}
              {isUserCanSeeAffiliates && (
                <NavLink to="/affiliate" className="admin-nav-link sub-menu-padding">
                  <FontAwesomeIcon icon={faArrowsRotate} className="me-2" />
                  <span>Affiliates</span>
                </NavLink>
              )}
              {isUserCanSeeEmailTemplates && (
                <NavLink to="/email-templates" className="admin-nav-link sub-menu-padding">
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                  <span>Email Templates</span>
                </NavLink>
              )}
              {isUserCanSeeSmtpPresets && (
                <NavLink to="/smtp-presets" className="admin-nav-link sub-menu-padding">
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                  <span>SMTP</span>
                </NavLink>
              )}
              {isUserCanSeeSmsServices && (
                <NavLink to="/sms-services" className="admin-nav-link sub-menu-padding">
                  <FontAwesomeIcon icon={faCommentSms} className="me-2" />
                  <span>Sms Services</span>
                </NavLink>
              )}
              {isUserCanSeeVoIP && (
                <NavLink to="/voip" className="admin-nav-link sub-menu-padding">
                  <FontAwesomeIcon icon={faPhoneSquare} className="me-2" />
                  <span>VoIP</span>
                </NavLink>
              )}
              {isSuperUser && (
                <NavLink to="/datafeed-settings" className="admin-nav-link sub-menu-padding">
                  <FontAwesomeIcon icon={faComputer} className="me-2" />
                  <span>Datafeed Settings</span>
                </NavLink>
              )}
              {isUserCanSeeVariables && (
              <NavLink to="/variables" className="admin-nav-link sub-menu-padding">
                <FontAwesomeIcon icon={faPen} className="me-2" />
                <span>Variables</span>
              </NavLink>
              )}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
export default NavigationMenu;
