import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { WalletAddressesModal } from './WalletAddressesModal';
import {
  deleteAdminAddress, setDefaultNetworkAdminAddress, getAdminAddresses, editAdminAddress, 
} from '../../redux/adminAddress/adminAddressActions';

function AdminAddress() {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [network, setNetwork] = useState('');
  const [currency, setCurrency] = useState('');
  const [currencies, setCurrencies] = useState([]);
  const [isModalShown, setIsModalShown] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState();

  const adminAddressesNetwork = useSelector((state) => state?.adminAddress?.adminAddressesNetwork);
  const adminAddressesNetworkfetched = useSelector((state) => state?.adminAddress?.adminAddressesNetworkfetched);
  const adminAddresses = useSelector((state) => state?.adminAddress?.adminAddresses);
  const adminAddressDeleted = useSelector((state) => state?.adminAddress?.adminAddressDeleted);
  const networks = useSelector((state) => state.network?.networks);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);

  useEffect(() => {
    dispatch(getAdminAddresses());
  }, []);

  const showModal = (id) => {
    setIsModalShown(true);
    setSelectedAddressId(id);
  };

  const hideModal = (id) => {
    setIsModalShown(false);
    setSelectedAddressId();
  };

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteAdminAddress(id));
      }
    });
  };

  const setDefaultAction = (id, currencyId, network) => {
    Swal.fire({
      title: 'Are you sure you want to set it as default Address for selected Currency?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        const data = {
          isDefault: true,
          currencyId,
        };

        if (network) {
          data.network = network;
        }

        dispatch(setDefaultNetworkAdminAddress(id, data));
      }
    });
  };

  const unsetDefault = (id) => {
    dispatch(editAdminAddress(id, { isDefault: false }));
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Admin Wallets </h3>
        {permissionName && permissionName.length > 0 && permissionName.includes('add_admin_address')
          ? <Link to="/add-admin-address"><button type="button" className="btn btn-default">Add Wallet</button></Link>
          : null}
        <div className="mt-3 table-responsive">
          <Table responsive>
            <thead className="table_head">
              <tr>
                <th>Address</th>
                <th>Currency</th>
                <th>Network</th>
                {permissionName && permissionName.length > 0 && permissionName.includes('edit_admin_address', 'delete_admin_address')
                  ? <th>Action(s)</th>
                  : null}
              </tr>
            </thead>
            <tbody className={adminAddresses && adminAddresses.length ? '' : 'no-admin-address'}>
              {adminAddresses && adminAddresses.length > 0 ? adminAddresses.map((adminAddress) => (
                <tr key={adminAddress._id}>
                  <td>{adminAddress.address}</td>
                  <td>{adminAddress.currency?.symbol}</td>
                  <td>{adminAddress?.network ?? '-'}</td>
                  <td className="action-buttons">
                    {permissionName && permissionName.length > 0 && permissionName.includes('set_default_admin_address')
                      ? adminAddress.isDefault 
                        ? <button type="button" className="btn btn-warning me-2" onClick={() => unsetDefault(adminAddress._id)}>Unset Default</button>
                        : <button type="button" className="btn btn-success me-2" onClick={() => setDefaultAction(adminAddress._id, adminAddress.currency?._id, adminAddress?.network)}>Set As Default</button>
                      : null}
                    {permissionName && permissionName.length > 0 && permissionName.includes('edit_admin_address')
                      ? <Link to={`/edit-admin-address/${adminAddress._id}`} className="btn btn-primary me-2 text-decoration-none text-light">Edit</Link>
                      : null}
                    {permissionName && permissionName.length > 0 && permissionName.includes('delete_admin_address')
                      ? <button type="button" className="btn btn-danger me-2" onClick={() => deleteAction(adminAddress._id)}>Delete</button>
                      : null}
                    {
                            permissionName && permissionName.length && permissionName.includes('edit_admin_address') && adminAddress.address?.startsWith('xpub')
                              ? <button type="button" className="btn btn-warning me-2" onClick={() => showModal(adminAddress._id)}>Generated Wallets</button>
                              : null
                          }
                  </td>
                </tr>
              ))
                : (
                  <tr>
                    <td colSpan={100} className="text-center">Please select Network and Currency from the above filter.</td>
                  </tr>
                )}
            </tbody>
          </Table>
          <WalletAddressesModal isShown={isModalShown} hideModal={hideModal} adminAddressId={selectedAddressId} />
        </div>
      </div>
    </div>
  );
}

export default AdminAddress;
